<template>
	<div class="chat">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image purple"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="ChatIcon"
				title="Chat to a Chaplain"
				colour="purple"
			/>
			<div class="panel">
				<div class="panel-top panel-text">
					<h2>What is this service and how should it be used?</h2>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel accumsan purus. Aenean cursus iaculis odio ac elementum. Suspendisse facilisis justo nec purus cursus, nec sodales mauris rhoncus. Donec tempor ligula eu convallis tempus. Suspendisse a vulputate dolor.</p>
				</div>
				<div class="panel-bottom">
					<Button icon="ChatIcon" class="none-bottom btn btn-purple btn-tick btn-square btn-icon btn-icon-right" text="Begin Live Chat"></Button>
					<span class="eyebrow alt">Or, Alternatively</span>
					<Button routeName="requests" icon="SendIcon" class="none-bottom btn btn-green btn-tick btn-square btn-icon btn-icon-right" text="Make a Request"></Button>
					<Button routeName="contact" icon="ContactIcon" class="btn-last none-bottom btn btn-grey btn-tick btn-square btn-icon btn-icon-right" text="Contact our Team"></Button>
				</div><!-- panel botom -->
			</div>
		</div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Button from '@/components/Button.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	export default {
		name: 'Chat',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			Button,
			SectionTitle
		},
		data () {
	        return {

	        };
	    },
	    methods: {

        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {

        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.panel {
		box-shadow: 5px 15px 15px 5px $snow;
	    border: 1px solid $snow;
	    border-radius: 12px;
	    text-align: left;
	    margin: 30px 0 50px;
	    .panel-top {
	    	padding: 30px;
	    	border-bottom: 1px solid $snow;
	    }
		.panel-bottom {
			padding: 30px;
			.eyebrow {
				text-align: center;
				margin: 17px 0 17px;
			}
		}
		.panel-text {
			h2 {
				font-size: 26px;
				line-height: 31px;
				letter-spacing: -0.65px;
				font-weight: $weight_600;
				color: $dark;
				margin-bottom: 15px;
			}
		}
	}
</style>
